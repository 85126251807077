<template>
    <div class='card'>
        <div class='flex justify-content-between mt-5'>
            <div class='col-6 flex justify-content-start'>
                <span class='p-text-secondary'>运维排口：</span>
                <Skeleton v-if='loading' width='8rem' height='1.35rem'></Skeleton>
                <span v-if='!loading'>{{ taskInfo.associatedSiteValue }}</span>
            </div>
            <div class='col-6 flex justify-content-start'>
                <span class='p-text-secondary'>运维项目：</span>
                <Skeleton v-if='loading' width='8rem' height='1.35rem'></Skeleton>
                <span v-if='!loading'>{{ taskInfo.taskName }}</span>
            </div>
        </div>
        <div class='flex justify-content-between mt-5'>
            <div class='col-6 flex justify-content-start'>
                <span class='p-text-secondary'>运维人员：</span>
                <Skeleton v-if='loading' width='8rem' height='1.35rem'></Skeleton>
                <span v-if='!loading'>{{ taskInfo.assignorValue }}</span>
            </div>
            <div class='col-6 flex justify-content-start'>
                <span class='p-text-secondary'>运维状态：</span>
                <Skeleton v-if='loading' width='8rem' height='1.35rem'></Skeleton>
                <span v-if='!loading'>{{ formatTaskState(taskInfo.currentTaskStatus) }}</span>
            </div>
        </div>
        <div class='flex justify-content-between mt-5'>
            <div class='col-6 flex justify-content-start'>
                <span class='p-text-secondary'>计划开始时间：</span>
                <Skeleton v-if='loading' width='16rem' height='1.35rem'></Skeleton>
                <span v-if='!loading'>{{ taskInfo.estimatedStartExecutionTime }}</span>
            </div>
            <div class='col-6 flex justify-content-start'>
                <span class='p-text-secondary'>计划结束时间：</span>
                <Skeleton v-if='loading' width='16rem' height='1.35rem'></Skeleton>
                <span v-if='!loading'>{{ taskInfo.estimatedExecutionEndTime }}</span>
            </div>
        </div>
        <div class='flex justify-content-between mt-5'>
            <div class='col-6 flex justify-content-start'>
                <span class='p-text-secondary'>实际开始时间：</span>
                <Skeleton v-if='loading' width='16rem' height='1.35rem'></Skeleton>
                <span v-if='!loading'>{{ taskInfo.actualStartTime }}</span>
            </div>
            <div class='col-6 flex justify-content-start'>
                <span class='p-text-secondary'>实际结束时间：</span>
                <Skeleton v-if='loading' width='16rem' height='1.35rem'></Skeleton>
                <span v-if='!loading'>{{ taskInfo.endTime }}</span>
            </div>
        </div>
        <div class='col-12 flex justify-content-start mt-5'>
            <span class='p-text-secondary'>运维备注信息：</span>
            <Skeleton v-if='loading' width='32rem' height='1.35rem'></Skeleton>
            <span v-if='!loading'>{{ taskInfo.taskDesc }}</span>
        </div>
    </div>
</template>
<script>
import TaskService from '../../service/TaskService';

export default {
    props: ['id'],
    data() {
        return {
            loading: true,
            taskInfo: {},
        };
    },
    taskService: null,
    created() {
        this.taskService = new TaskService();
    },
    mounted() {
        this.taskService.getTaskDetail(this.id).then(res => {
            console.log(res);
            if (res.data.success) {
                this.taskInfo = res.data.data;
            }
            setTimeout(() => this.loading = false, 500);
        });
    },
    methods: {
        formatTaskState(state) {
            if (state === 1) {
                return '未开始';
            }
            if (state === 6) {
                return '已完成';
            } else {
                return '处理中';
            }
        },
    },
};
</script>
<style>
</style>