import request from '../config/axios';
import store from '../store';

export default class TaskService {
    getTasksInMonth = (month) => request({
        url: '/taskCalendar/' + month, method: 'get', params: {
            'siteId': store.getters.currentSelectedSite.id,
        },
    });

    getTaskDetail = (id) => request({
        url: '/maintainingTaskFlow/' + id, method: 'get',
    });

    getDigitalRecordsOfTask = (id) => request({
        url: '/recordOfMaintain/recordsOfProcessIns/' + id, method: 'get',
    });

    historyRecordByDateRange = (siteId, startTime, endTime) => {
        return request({
            url: '/workReport/historyRecordByDateRange',
            method: 'get',
            params: { 'siteId': siteId, 'startTime': startTime, 'endTime': endTime },
        });
    };
}