import fecha from 'fecha';

export const calcDate = (date1, date2) => {
    const date3 = date2 - date1;

    const days = Math.floor(date3 / (24 * 3600 * 1000));

    const leave1 = date3 % (24 * 3600 * 1000); // 计算天数后剩余的毫秒数
    const hours = Math.floor(leave1 / (3600 * 1000));

    const leave2 = leave1 % (3600 * 1000); // 计算小时数后剩余的毫秒数
    const minutes = Math.floor(leave2 / (60 * 1000));

    const leave3 = leave2 % (60 * 1000); // 计算分钟数后剩余的毫秒数
    const seconds = Math.round(date3 / 1000);
    return {
        leave1,
        leave2,
        leave3,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
    };
};
const now = new Date(); // 当前日期
const nowDayOfWeek = (now.getDay() === 0) ? 7 : now.getDay() - 1; // 今天是本周的第几天。周一=0，周日=6
// var nowMonth = now.getMonth() // 当前月值（1月=0，12月=11）
// var nowYear = now.getFullYear()

// 季度初
export function getQuarterStartDate(date) {
    return new Date(date.getFullYear(), getQuarterStartMonth(date), 1);
}

// 季度末。下季初-1天
export function getQuarterEndDate(date) {
    return AddDays(AddMonths(getQuarterStartDate(date), 3), -1);
}
// 日期+天
export function AddDays(d, n) {
    const t = new Date(d);// 复制并操作新对象，避免改动原对象
    t.setDate(t.getDate() + n);
    return t;
}

// 日期+月。日对日，若目标月份不存在该日期，则置为最后一日
export function AddMonths(d, n) {
    const t = new Date(d);
    t.setMonth(t.getMonth() + n);
    if (t.getDate() !== d.getDate()) {
        t.setDate(0);
    }
    return t;
}

// 日期+年。月对月日对日，若目标年月不存在该日期，则置为最后一日
export function AddYears(d, n) {
    const t = new Date(d);
    t.setFullYear(t.getFullYear() + n);
    if (t.getDate() !== d.getDate()) {
        t.setDate(0);
    }
    return t;
}

// 获得本季度的开始月份
export function getQuarterStartMonth(date) {
    const now = new Date(date); // 当前日期
    const nowMonth = now.getMonth(); // 当前月值（1月=0，12月=11）
    if (nowMonth <= 2) {
        return 0;
    } else if (nowMonth <= 5) {
        return 3;
    } else if (nowMonth <= 8) {
        return 6;
    } else {
        return 9;
    }
}

// 周一
export function getWeekStartDate() {
    return AddDays(now, -nowDayOfWeek);
}

// 周日。本周一+6天
export function getWeekEndDate() {
    return AddDays(getWeekStartDate(), 6);
}

// 月初
export function getMonthStartDate(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
}

// 月末。下月初-1天
export function getMonthEndDate(date) {
    return AddDays(AddMonths(getMonthStartDate(date), 1), -1);
}
/**
 * 获取最近一小时开始结束时间戳
 */
export function lastestHourTime() {
    const curr = new Date().getTime();
    const lastestHour = curr - 60 * 60 * 1000;
    return {startTs: lastestHour, endTs: curr};
}

/**
 * 获取最近30天开始结束时间戳
 */
export function lastestMonthTime() {
    const curr = new Date().getTime();
    const lastestWeek = curr - 60 * 60 * 1000 * 24 * 30;
    return {startTs: lastestWeek, endTs: curr};
}
/**
 * 获取最近一天开始结束时间戳
 */
export function lastestDayTime() {
    const curr = new Date().getTime();
    const lastestDay = curr - 60 * 60 * 1000 * 24;
    return {startTs: lastestDay, endTs: curr};
}
/**
 * 获取最近一周开始结束时间戳
 */
export function lastestWeekTime() {
    const curr = new Date().getTime();
    const lastestWeek = curr - 60 * 60 * 1000 * 24 * 7;
    return {startTs: lastestWeek, endTs: curr};
}
/**
 * 日期格式化
 */
export function dateFormat(date) {
    let format = 'yyyy-MM-dd hh:mm:ss';
    if (date !== 'Invalid Date') {
        const o = {
            'M+': date.getMonth() + 1, // month
            'd+': date.getDate(), // day
            'h+': date.getHours(), // hour
            'm+': date.getMinutes(), // minute
            's+': date.getSeconds(), // second
            'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
            'S': date.getMilliseconds(), // millisecond
        };
        if (/(y+)/.test(format)) {
            format = format.replace(RegExp.$1,
                (date.getFullYear() + '').substr(4 - RegExp.$1.length));
        }
        for (const k in o) {
            if (new RegExp('(' + k + ')').test(format)) {
                format = format.replace(RegExp.$1,
                    RegExp.$1.length === 1 ? o[k] :
                        ('00' + o[k]).substr(('' + o[k]).length));
            }
        }
        return format;
    }
    return '';
}
export function dateFormats(date) {
    let format = 'yyyyMMdd';
    if (date !== 'Invalid Date') {
        const o = {
            'M+': date.getMonth() + 1, // month
            'd+': date.getDate(), // day
            'h+': date.getHours(), // hour
            'm+': date.getMinutes(), // minute
            's+': date.getSeconds(), // second
            'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
            'S': date.getMilliseconds(), // millisecond
        };
        if (/(y+)/.test(format)) {
            format = format.replace(RegExp.$1,
                (date.getFullYear() + '').substr(4 - RegExp.$1.length));
        }
        for (const k in o) {
            if (new RegExp('(' + k + ')').test(format)) {
                format = format.replace(RegExp.$1,
                    RegExp.$1.length === 1 ? o[k] :
                        ('00' + o[k]).substr(('' + o[k]).length));
            }
        }
        return format;
    }
    return '';
}

export function formatDate(date, fmt) {
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    const o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
    };
    for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            const str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
        }
    }
    return fmt;
}
export function firstDay(dateValue) {
    // 获取月第一天
    const date = dateValue;
    date.setDate(1);
    let month = parseInt(date.getMonth() + 1);
    let day = date.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return date.getFullYear() + '-' + month + '-' + day;
}
export function lastDay(dateValue) {
    // 获取月的最后一天
    const date = dateValue;
    let currentMonth = date.getMonth();
    const nextMonth = ++currentMonth;
    const nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
    const oneDay = 1000 * 60 * 60 * 24;
    const lastTime = new Date(nextMonthFirstDay - oneDay);
    let month = parseInt(lastTime.getMonth() + 1);
    let day = lastTime.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return date.getFullYear() + '-' + month + '-' + day;
}

function padLeftZero(str) {
    return ('00' + str).substr(str.length);
}

export function recentlyMonth() {
    const months = [];
    const m = new Date();
    for (let i = 0; i < 5; i++) {
        const beginDate = fecha.parse(fecha.format(m, 'YYYY-MM'), 'YYYY-MM');
        let endDate = new Date(m.getTime());
        endDate.setMonth(endDate.getMonth() + 1);
        endDate = new Date(fecha.parse(fecha.format(endDate, 'YYYY-MM'), 'YYYY-MM').getTime() - 1);
        months.push({
            label: i == 0 ? '本月' : fecha.format(beginDate, 'YYYY-MM'),
            value: {
                begin: beginDate,
                end: endDate,
            },
        });
        // 往前一个月
        m.setMonth(m.getMonth() - 1);
    }
    return months;
}
