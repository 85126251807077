<template>
    <div class='card'>
        <div v-if='loading' style='height:200px;' class='flex flex-wrap'>
            <Skeleton width='70%' height='1.5rem' />
            <Skeleton width='70%' height='1.5rem' />
            <Skeleton width='70%' height='1.5rem' />
            <Skeleton width='70%' height='1.5rem' />
            <Skeleton width='70%' height='1.5rem' />
        </div>
        <div v-if='!loading'>
            <div v-if='recordList.length < 1' class='flex justify-content-center' style='height:200px;'>
            <span class='align-self-center '>
                没有台账数据
            </span>
            </div>
            <div v-for='item of recordList' :key='item.id'>
                <div class='flex justify-content-between mb-2'>
                    <div>
                        <span>{{ formatDigitalBookName(item.formId) }}</span>
                        <span class='ml-1 text-sm'
                              :class="item.approveResult === 1? 'text-primary':'p-text-secondary'">({{ formatApproveState(item.approveResult)
                            }})</span>
                    </div>
                    <Button label='查看' class='p-button-text mr-2' @click='openPdf(item.id)' />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TaskService from '../../service/TaskService';

export default {
    props: ['id'],
    emits: ['show-pdf-report'],
    data() {
        return {
            loading: true,
            recordList: [],
        };
    },
    taskService: null,
    created() {
        this.taskService = new TaskService();
    },
    mounted() {
        this.taskService.getDigitalRecordsOfTask(this.id).then(res => {
            if (res.data.success) {
                this.recordList = res.data.data;
            }

            setTimeout(() => this.loading = false, 500);
        });
    },
    methods: {
        openPdf(id) {
            this.$emit('show-pdf-report', id);
        },
        formatApproveState(result) {
            if (result === null || result === '') {
                return '未审核';
            }
            if (result === 0) {
                return '被驳回';
            }
            if (result === 1) {
                return '已审核';
            }
        },
        formatDigitalBookName(key) {
            switch (key) {
                case 'LXXJ':
                    return '例行巡检记录';
                case 'HCJZ':
                    return '标样核查及校准结果记录';
                case 'WXJL':
                    return '维修记录';
                case 'YHPGH':
                    return '易耗品更换记录';
                case 'BYGH':
                    return '标准样品更换记录';
                case 'SYDB':
                    return '实际水样对比试验结果记录';
                case 'GZBG':
                    return '在线监测设备异常说明';
                default:
                    return '未知';
            }
        },
    },
};
</script>