<template>
    <div>
        <Toolbar class='mb-2'>
            <template v-slot:start>
                <label for='range' class=''>时间</label>
                <Dropdown v-model='dataRangeType' :options='dataRangeTypeOptions' optionValue='value'
                          optionLabel='label' @change='onDataRangeTypeChange' placeholder='选择时间' class='ml-2' />
                <div v-if="dataRangeType === 'month'">
                    <Calendar id='range' v-model='dateRangeBegin' view='month' dateFormat='yy-mm' :showIcon='true'
                              @date-select='onDateChange' class='ml-2' />
                </div>
                <div v-if="dataRangeType === 'year' || dataRangeType === 'quarter'">
                    <Calendar id='range' v-model='dateRangeBegin' view='year' dateFormat='yy' :showIcon='true'
                              @date-select='onDateChange' class='ml-2' />
                </div>
                <Dropdown v-if="dataRangeType === 'quarter'" v-model='quarterType' :options='quarterOptions'
                          @change='onDataRangeTypeChange' placeholder='选择季度' class='ml-2' />
                <div v-if="dataRangeType === 'day'">
                    <Calendar id='range' v-model='dateRangeBegin' dateFormat='yy-mm-dd' :showIcon='true'
                              @date-select='onDateChange' class='ml-2' />
                </div>
            </template>
            <template v-slot:end>
                <SelectButton v-model='tableView' :options="['全部','精简']" />
            </template>
        </Toolbar>
        <DataTable :value='handleTableData()' responsiveLayout='scroll' showGridlines>
            <ColumnGroup type='header'>
                <Row>
                    <Column header='月份' :rowspan='2' headerStyle='' style='text-align: center'></Column>
                    <Column header='日期' :rowspan='2' headerClass='text-center text-bold'></Column>
                    <Column header='巡检维护' :rowspan='2'></Column>
                    <Column header='标液核查及校准' :rowspan='2'></Column>
                    <Column header='维修设备' :rowspan='2'></Column>
                    <Column header='易耗品更换' :rowspan='2'></Column>
                    <Column header='标液更换' :rowspan='2'></Column>
                    <Column header='实际水样比对' :rowspan='2'></Column>
                    <Column header='第三方比对' :rowspan='2'></Column>
                    <Column header='异常处理' :colspan='4'></Column>
                </Row>
                <Row>
                    <Column header='异常时间'></Column>
                    <Column header='异常说明'></Column>
                    <Column header='处理结果'></Column>
                    <Column header='是否出报告'></Column>
                </Row>
            </ColumnGroup>
            <Column field='month'></Column>
            <Column field='day'></Column>
            <Column field='lxxj' class='text-center'>
                <template #body='slotProps'>
                    <div v-if='slotProps.data.lxxj'>
                        <!--                        v-tooltip.right="'点击查看详情'"-->
                        <Button icon='pi pi-check' class='p-button-rounded p-button-sm'
                                style='width: 1.5rem;height: 1.5rem;' v-tooltip.right="'点击查看详情'"
                                @click="showRecordDetails(slotProps.data,'LXXJ')" />
                    </div>
                </template>
            </Column>
            <Column field='hcjz' class='text-center'>
                <template #body='slotProps'>
                    <div v-if='slotProps.data.hcjz'>
                        <Button icon='pi pi-check' class='p-button-rounded p-button-sm'
                                style='width: 1.5rem;height: 1.5rem;' v-tooltip.right="'点击查看详情'"
                                @click="showRecordDetails(slotProps.data,'HCJZ')" />
                    </div>
                </template>
            </Column>
            <Column field='wxjl' class='text-center'>
                <template #body='slotProps'>
                    <div v-if='slotProps.data.wxjl'>
                        <Button icon='pi pi-check' class='p-button-rounded p-button-sm'
                                style='width: 1.5rem;height: 1.5rem;' v-tooltip.right="'点击查看详情'"
                                @click="showRecordDetails(slotProps.data,'WXJL')" />
                    </div>
                </template>
            </Column>
            <Column field='yhpgh' class='text-center'>
                <template #body='slotProps'>
                    <div v-if='slotProps.data.yhpgh'>
                        <Button icon='pi pi-check' class='p-button-rounded p-button-sm'
                                style='width: 1.5rem;height: 1.5rem;' v-tooltip.right="'点击查看详情'"
                                @click="showRecordDetails(slotProps.data,'YHPGH')" />
                    </div>
                </template>
            </Column>
            <Column field='bygh' class='text-center'>
                <template #body='slotProps'>
                    <div v-if='slotProps.data.bygh'>
                        <Button icon='pi pi-check' class='p-button-rounded p-button-sm'
                                style='width: 1.5rem;height: 1.5rem;' v-tooltip.right="'点击查看详情'"
                                @click="showRecordDetails(slotProps.data,'BYGH')" />
                    </div>
                </template>
            </Column>
            <Column field='sydb' class='text-center'>
                <template #body='slotProps'>
                    <div v-if='slotProps.data.sydb'>
                        <Button icon='pi pi-check' class='p-button-rounded p-button-sm'
                                style='width: 1.5rem;height: 1.5rem;' v-tooltip.right="'点击查看详情'"
                                @click="showRecordDetails(slotProps.data,'SYDB')" />
                    </div>
                </template>
            </Column>
            <Column field='dsfdb' class='text-center'>
                <template #body='slotProps'>
                    <div v-if='slotProps.data.dsfdb'>
                        <Button icon='pi pi-check' class='p-button-rounded p-button-sm'
                                style='width: 1.5rem;height: 1.5rem;' v-tooltip.right="'点击查看详情'"
                                @click="showRecordDetails(slotProps.data,'DSFDB')" />
                    </div>
                </template>
            </Column>
            <!--            <Column field='mykh'></Column>-->
            <Column field='ycsj' class='col-1 text-center'>
                <template #body='slotProps'>
                    <div><span>{{ slotProps.data.ycsj }}</span></div>
                </template>
            </Column>
            <Column field='ycxx' class='col-2 text-center'>
                <template #body='slotProps'>
                    <div><span>{{ slotProps.data.ycxx }}</span></div>
                </template>
            </Column>
            <Column field='ycyy' class='text-center'></Column>
            <Column field='bgwj' class='text-center'>
                <template #body='slotProps'>
                    <div v-if='slotProps.data.bgwj'>
                        <Button icon='pi pi-check' class='p-button-rounded p-button-sm'
                                style='width: 1.5rem;height: 1.5rem;' v-tooltip.right="'点击查看详情'"
                                @click="showRecordDetails(slotProps.data,'GZBG')" />
                    </div>
                </template>
            </Column>
        </DataTable>
        <Dialog v-model:visible='eventDialog' :style="{width: '50%',zIndex: 99999}" header='运维详情' :modal='true'
                :closable='true' @hide='closeDialog' maximizable>
            <TabMenu :model='items' v-model:active-index='activeIndex' />
            <TaskBasicInfo v-bind:id='taskId' v-if='activeIndex === 0'></TaskBasicInfo>
            <TaskHandleProcess v-bind:id='taskId' v-if='activeIndex === 1'
                               @show-pdf-report='showPdfReport'></TaskHandleProcess>
            <template #footer />
        </Dialog>
        <Sidebar v-model:visible='visibleLeft' class='p-sidebar-lg' style='border-radius: 0;width:50%'>
            <div>
                <LXXJ v-if="currentRecord.formId === 'LXXJ'" :processItem='currentRecord'></LXXJ>
                <HCJZ v-if="currentRecord.formId === 'HCJZ'" :processItem='currentRecord'></HCJZ>
                <WXJL v-if="currentRecord.formId === 'WXJL'" :processItem='currentRecord'></WXJL>
                <YHPGH v-if="currentRecord.formId === 'YHPGH'" :processItem='currentRecord'></YHPGH>
                <BYGH v-if="currentRecord.formId === 'BYGH'" :processItem='currentRecord'></BYGH>
                <SYBD v-if="currentRecord.formId === 'SYDB'" :processItem='currentRecord'></SYBD>
                <GZBG v-if="currentRecord.formId === 'GZBG'" :processItem='currentRecord'></GZBG>
                <DSFDB v-if="currentRecord.formId === 'DSFDB'" :processItem='currentRecord'></DSFDB>
                <MYKH v-if="currentRecord.formId === 'MYKH'" :processItem='currentRecord'></MYKH>
            </div>
        </Sidebar>
    </div>
</template>
<script>
import TaskService from '../service/TaskService';
import dayjs from 'dayjs';
import { dateFormat, getMonthEndDate, getMonthStartDate, getQuarterEndDate, getQuarterStartDate } from '../utils/date';
import TaskBasicInfo from './digitalRecords/TaskBasicInfo';
import TaskHandleProcess from './digitalRecords/TaskHandleProcess';
import LXXJ from './digitalRecords/LXXJ';
import HCJZ from './digitalRecords/HCJZ';
import WXJL from './digitalRecords/WXJL';
import YHPGH from './digitalRecords/YHPGH';
import BYGH from './digitalRecords/BYGH';
import SYBD from './digitalRecords/SYBD';
import GZBG from './digitalRecords/GZBG';
import DSFDB from './digitalRecords/DSFDB';
import MYKH from './digitalRecords/MYKH';
import fecha from 'fecha';

export default {
    components: {
        TaskBasicInfo,
        TaskHandleProcess,
        LXXJ,
        HCJZ,
        WXJL,
        YHPGH,
        BYGH,
        SYBD,
        GZBG,
        DSFDB,
        MYKH,
    },
    data() {
        return {
            eventDialog: false,
            visibleLeft: false,
            taskId: '',
            currentRecord: {},
            items: [
                { label: '基本信息', icon: 'pi pi-fw pi-home' },
                { label: '处理过程', icon: 'pi pi-fw pi-table' },
            ],
            activeIndex: 0,
            tableView: '全部',
            dataArray: [],
            taskTableData: [],
            dateRangeBegin: null,
            dateRangeEnd: null,
            dataRangeType: 'year',
            quarterType: '第一季度',
            dataRangeTypeOptions: [
                { label: '年度统计', value: 'year' },
                { label: '季度统计', value: 'quarter' },
                { label: '月度统计', value: 'month' },
            ],
            quarterOptions: ['第一季度', '第二季度', '第三季度', '第四季度'],
        };
    },
    taskService: null,
    created() {
        this.dataRangeType = 'month';
        this.taskService = new TaskService();
    },
    mounted() {
        this.dateRangeBegin = dayjs().startOf('month').toDate();
        this.dateRangeEnd = dayjs().endOf('month').toDate();
        this.loadTableData();
    },
    methods: {
        loadTableData() {
            const start = dayjs(this.dateRangeBegin.getTime()).startOf('day');
            const end = dayjs(this.dateRangeEnd.getTime()).startOf('day');
            const number = end.diff(start, 'day');
            const x = [];
            this.taskTableData = [];
            for (let i = 0; i <= number; i++) {
                const cur = start.add(i, 'day');
                x.push(cur.format('YYYY-MM-DD'));
                this.taskTableData.push({ 'month': cur.format('YYYY-MM'), 'day': cur.format('DD') });
            }

            const siteId = this.$store.getters.currentSelectedSite.id;
            this.taskService.historyRecordByDateRange(siteId, start.valueOf(), end.valueOf()).then((res) => {
                if (res.data.success) {
                    this.dataArray = res.data.data;
                    this.handleEachTaskList(x, this.dataArray.LXXJ, 0, 'lxxj');
                    this.handleEachTaskList(x, this.dataArray.HCJZ, 1, 'hcjz');
                    this.handleEachTaskList(x, this.dataArray.WXJL, 2, 'wxjl');
                    this.handleEachTaskList(x, this.dataArray.YHPGH, 3, 'yhpgh');
                    this.handleEachTaskList(x, this.dataArray.BYGH, 4, 'bygh');
                    this.handleEachTaskList(x, this.dataArray.SYDB, 5, 'sydb');
                    this.handleEachTaskList(x, this.dataArray.DSFDB, 6, 'dsfdb');
                    this.handleEachTaskList(x, this.dataArray.MYKH, 7, 'mykh');

                    const tempMap = {};
                    (this.dataArray.GZBG ?? []).forEach((e) => {
                        // const date = dayjs(e.gmtCreate).format('YYYY-MM-DD');
                        const date = e.gmtCreate.substring(0, 10);
                        if (tempMap[date]) {
                            tempMap[date].push(e);
                        } else {
                            tempMap[date] = [e];
                        }
                    });
                    for (let i = 0; i < x.length; i++) {
                        if (tempMap[x[i]]) {
                            this.taskTableData[i]['rowspan'] = tempMap[x[i]].length;
                            this.taskTableData[i].hasData = true;
                            for (let j = 0; j < tempMap[x[i]].length; j++) {
                                const context = JSON.parse(tempMap[x[i]][j].formContext);
                                this.taskTableData[i]['ycsj'] = context.startTime + ' 至 ' + context.endTime;
                                this.taskTableData[i]['ycxx'] = context.factors + ',' + context.reason;
                                this.taskTableData[i]['ycyy'] = context.result;
                                this.taskTableData[i]['bgwj'] = '是';
                            }
                        }
                    }
                }
            });
            // console.log(this.taskTableData);
        },
        handleEachTaskList(x, list, index, tableKey) {
            if (list == null) list = [];
            const tempMap = {};
            list.forEach((e) => {
                // const date = dayjs(e.gmtCreate).format('YYYY-MM-DD');
                const date = e.gmtCreate.substring(0, 10);
                // console.log(date);
                if (tempMap[date]) {
                    tempMap[date] += 1;
                } else {
                    tempMap[date] = 1;
                }
            });
            for (let i = 0; i < x.length; i++) {
                if (tempMap[x[i]]) {
                    this.taskTableData[i][tableKey] = tempMap[x[i]];
                    this.taskTableData[i].hasData = true;
                }
            }
        },
        onDataRangeTypeChange() {
            this.dateRangeBegin = new Date();
            this.getBeginTimeAndEndTime();
            console.log('begin:', dateFormat(this.dateRangeBegin), 'end:', dateFormat(this.dateRangeEnd));
            this.loadTableData();
        },
        onDateChange() {
            this.getBeginTimeAndEndTime();
            console.log('begin:', dateFormat(this.dateRangeBegin), 'end:', dateFormat(this.dateRangeEnd));
            this.loadTableData();
        },
        getBeginTimeAndEndTime() {
            const dates = this.dateRangeBegin;
            if (this.dataRangeType === 'year') {
                const year = dates.getFullYear();
                // const begin = new Date(year + '-01-01 00:00:00');
                // const end = new Date(year + '-12-30 23:59:59');
                const begin = fecha.parse(year + '-01-01 00:00:00', 'YYYY-MM-DD HH:mm:ss');
                const end = fecha.parse(year + '-12-30 23:59:59', 'YYYY-MM-DD HH:mm:ss');
                this.dateRangeBegin = begin;
                this.dateRangeEnd = end;
            } else if (this.dataRangeType === 'quarter') {
                let quarter = '';
                // 第一季度
                if (this.quarterType === '第一季度') {
                    quarter = fecha.parse(dates.getFullYear() + '-01-01 00:00:00', 'YYYY-MM-DD HH:mm:ss');
                }
                // 第二季度
                if (this.quarterType === '第二季度') {
                    quarter = fecha.parse(dates.getFullYear() + '-04-01 00:00:00', 'YYYY-MM-DD HH:mm:ss');
                }
                // 第三季度
                if (this.quarterType === '第三季度') {
                    quarter = fecha.parse(dates.getFullYear() + '-07-01 00:00:00', 'YYYY-MM-DD HH:mm:ss');
                }
                // 第四季度
                if (this.quarterType === '第四季度') {
                    quarter = fecha.parse(dates.getFullYear() + '-10-01 00:00:00', 'YYYY-MM-DD HH:mm:ss');
                }
                this.dateRangeBegin = getQuarterStartDate(quarter);
                this.dateRangeEnd = getQuarterEndDate(quarter);
            } else if (this.dataRangeType === 'month') {
                // 月初
                this.dateRangeBegin = getMonthStartDate(dates);
                // 月末
                this.dateRangeEnd = getMonthEndDate(dates);
            } else if (this.dataRangeType === 'day') {
                const date = new Date(dates.getTime());
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                date.setMilliseconds(0);
                this.dateRangeBegin = new Date(date.getTime());
                date.setDate(date.getDate() + 1);
                this.dateRangeEnd = date;
            }
        },
        handleTableData() {
            if (this.tableView === '精简') {
                return this.taskTableData.filter((d) => d.hasData === true);
            }
            return this.taskTableData;
        },
        showRecordDetails(data, key) {
            const date = data.month + '-' + data.day;
            const tempArray = this.dataArray[key.toUpperCase()].filter((d) => d.gmtCreate.indexOf(date) > -1);
            console.log(tempArray);
            if (tempArray.length > 0) {
                this.currentRecord = tempArray[0];
                this.taskId = tempArray[0].taskId;
                this.eventDialog = true;
            }
        },
        showPdfReport() {
            this.visibleLeft = true;
        },
        closeDialog() {
            this.activeIndex = 0;
        },
    },
};
</script>
<style></style>
